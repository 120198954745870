// Bootstrap library.
@import 'bootstrap';

// Base-theme overrides.
@import 'overrides';

// Base-theme overrides.
@import 'variable-overrides';

// Additional mixins.
@import 'user-mixins';

// Material design colors
@import '_colors';

// Styles for used elements
@import 'elements';

.overlay{
	display:none;
	&.shadow{
		top:-56px;
		position:absolute;
		display:block;
		width:100%;
		height:100%;
		background:rgba(0, 0, 0, 0.5);
		z-index:1000;
		@media @normal{
			display: none;
		}
	}
	&.shadowwide{
		top:-56px;
		position:absolute;
		display:block;
		width:100%;
		height:100%;
		background:rgba(0, 0, 0, 0.5);
		z-index:1;
	}
}
.header{
	color:@text-color;
	display:block;
	position:fixed;
	width:100%;
	min-height:100%;
	z-index: 1001;
	left:-100%;
	top:0;
	padding:0;
	.box-shadow(0 0 15px rgba(0, 0, 0, 0.25));
	.myanimated;
	.header_wrapper{
		.transition(all 300ms ease);
		position: absolute;
		width:calc(~'100% - 40px');
		height:100%;
		left:-100%;
		background:@white;
		overflow-y: scroll;
		z-index: 10000;
	}
	.close{
		.transition(all 300ms ease);
		color:@white;
		font-size:16pt;
		width:40px;
		height:40px;
		background:@theme-color-1;
		position: absolute;
		top:0;
		right:100%;
		padding:5px 10px;
		z-index:9999;
		.box-shadow(inset 0px 0px 5px rgba(0, 0, 0, 0.15));
		cursor: pointer;
		opacity:1;
		font-weight:normal;
		padding-top:0.35em;
	}
	.clickme{
		position: absolute;
		z-index: 9998;
		height:100%;
		width:100%;
	}
	&.open{
		left:0;
		top:0;
		.header_wrapper{
			left:0;
		}
		.close{
			right:0;
		}
	}
	a{
		color:@gray-light;
	}
	.topbar{
		background:#333;
		color:#fff;
		text-align: right;
		padding:5px;
		a{
			color:#fff;
		}
	}
	.topmenu{
		.myanimated;
		.logo{
			padding:0.5em 0;
			margin:0.5em 0 2em 0;
			border-bottom:1px solid @gray;
			text-align:center;
			.myanimated;
			img{
				max-width:120px;
				width:100%;
				height: auto;
				.myanimated;
			}
		}
		.mainmenu{
			padding: 0;
			min-height:100%;
			position: relative;
			.nav{
				padding:1rem 0;
				margin:0;
				background: none;
				display: block;
				position: relative;
				height: 100%;
				width:100%;
				left:0;
				top:0;
				z-index: 1;
				box-sizing: border-box;
				li{
					display: block;
					position: relative;
					box-sizing: border-box;
					a{
						display: block;
						margin:0;
						padding:0.5rem 0;
						background:none;
						border-bottom:1px solid rgba(255, 255, 255, 0.15);
						text-transform: uppercase;
						font-weight: 400;
						color:@text-color;
						white-space: normal;
						&:hover{
							color: @theme-color-1;
							text-decoration:none;
						}
						&.active{
							color: @theme-color-1;
						}
						&.search-item{
							font-size: 0;
							&:before{
								font-size:@font-size-h4;
								color:@theme-color-1;
								display: inline-block;
								font-family: @font-family-awesome;
								content:'\f002';
								line-height: 1.2
							}
						}
					}
					> .dropdown-menu{
						background: @white;
						padding:1rem;
						margin:0;
						display: none;
						position: absolute;
						width:100%;
						height:100vh;
						left:100%;
						top:0;
						.myanimated;
						z-index: 10;
						.box-shadow(none);
						border:none;
						box-sizing: border-box;
						overflow-x: hidden;
						.border-radius(0);
					}
					&.open{
						> .dropdown-menu{
							left:0;
							overflow-y: hidden;
						}
					}
					&.prevlevel{
						padding:0.5rem 0;
						border-bottom:2px solid rgba(255, 255, 255, 0.5);
						.menuGoBack{
							&:before{
								content:'\f104';
								font-family: @font-family-awesome;
								display: inline-block;
								font-size: @font-size-h2;
								color:@text-color;
								margin:0 0.5em 0 0;
								padding:0;
								vertical-align: sub;
							}
						}
					}
				}
			}
		}
	}
	@media @normal{
		z-index: 100;
		position:relative;
		width:100%;
		min-height:0;
		left:0;
		.box-shadow(none);
		background:@white;
		border-bottom:1px solid @gray-lightest;
		.header_wrapper{
			position: relative;
			width:100%;
			height:auto;
			left:0;
			.clearfix;
			overflow-y: visible;
			background: none;
			
		}
		&:after{
			display:none;
		}
		.close{
			display: none;
		}
		.clickme{
			display: none;
		}
		.topmenu{
			padding:0;
			.clearfix;
			.logo{
				margin:10px 0 0 0;
				padding:0;
				border-top:none;
				border-bottom:none;
				text-align:left;
				img{
					width:100%;
					max-width:180px;
					height:auto;
				}
			}
			.mainmenu{
				padding: 0;
				min-height:100%;
				position: relative;
				.nav{
					padding:0;
					margin:0;
					background: none;
					display: block;
					position: relative;
					height: 100%;
					width:100%;
					left:0;
					top:0;
					z-index: 1;
					box-sizing: border-box;
					text-align: right;
					margin-bottom:-1px;
					li{
						display: inline-block;
						position: relative;
						box-sizing: border-box;
						a{
							display: block;
							margin:0;
							padding:1.5rem 1rem;
							background:none;
							border-bottom:none;
							text-transform: uppercase;
							font-weight: 400;
							color:@text-color;
							white-space: normal;
							.caret{
								display: none;
							}
								&.active{
								background: @theme-color-1;
								color:@white;
								&:before{
									color:@white;
								}
							}
						}
						> .dropdown-menu{
							font-size: @font-size-small;
							padding:1rem 0;
							margin:0;
							display: block;
							position: absolute;
							width:200px;
							height:auto;
							right:-100px;
							left:auto;
							top:99%;
							.myanimated;
							z-index: 10;
							.box-shadow(0 0 20px rgba(0, 0, 0, 0.3));
							border:none;
							box-sizing: border-box;
							overflow-x: visible;
							visibility: hidden;
							opacity:0;
							transition: 0.1s 0.1s;
							li{
								padding: 0;
								width:200px;
								display: block;
								position: static;
								a{
									border-bottom:1px solid rgba(255, 255, 255, 0.15);
									padding:0.5em 1rem;
									text-transform: none;
								}
							}
						}
						&.open{
							> .dropdown-menu{
								left:0;
								overflow-y: hidden;
							}
						}
						&:hover{
							/*background: @theme-color-1;*/
							> .dropdown-menu{
								visibility: visible;
								opacity:1;
								transition-delay: 0s;
							}
						}
					}
				}
			}
		}
	}
	@media @wide{
		.topmenu{
			.logo{
				img{
					max-width:200px;
				}
			}
			.mainmenu{
				.nav{
					li{
						a{
							padding:1.75rem 1rem;
						}
					}
				}
			}

		}
	}
	@media @xwide{
		.topmenu{
			.mainmenu{
				.nav{
					li{
						a{
							padding:2.5rem 1rem;
						}
						position: relative;
						> .dropdown-menu{
							width:240px;
							right:auto;
							left:0;
							li{
								width:240px;
							}
						}
					}
				}
			}
			.logo{
				img{
					max-width:246px;
				}
			}
		}
	}
}
.mobile_header{
	display:block;
	padding:0.5em 1rem;
	background:@white;
	color:#333;
	vertical-align: middle;

    top: 0;
    z-index: 100;
    width: 100%;
    .box-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
    .clearfix;
	@media @normal{
		display:none;
	}
	.menu-btn{
		display: block;
		font-size: 18pt;
		font-weight: normal;
		padding:0.25em;
		.border-radius(3px);
		width:40px;
		height:40px;
		text-align: center;
		cursor: pointer;
		float:right;
	}
	.header-title{
		display: block;
		float:left;
		font-size: @font-size-large;
		line-height: 1;
		padding:0;
		img{
			max-height: 40px;
			max-width: 180px;
		}
	}
	.topbar{
		background:#fff;
		border-bottom:1px dotted #ccc;
		color:#333;
		text-align: right;
		padding:5px 0px 5px 5px;
		margin-bottom: 5px;
		
		a{
			color: @theme-color-1;
		}
	}
}
html.mm-front .mm-slideout{
	z-index: 1000 !important;
}
.mm-opened .mobile_header .menu-btn:before{
	content:'✕';
}

.mmenu-nav{
	&.mm-basic{
		background:@white;
		.text-shadow(none);
		.logo{
			padding:0.5em 0;
			margin:0.5em 0 2em 0;
			border-bottom:1px dotted #eee;
			text-align:center;
			.myanimated;
			img{
				max-width:180px;
				width:100%;
				height: auto;
				.myanimated;
			}
		}
		.mm-list{
			padding: 0;
			position: relative;
			ul{
				padding:1rem 0;
				margin:0;
				background: none;
				display: block;
				position: relative;
				z-index: 1;
				box-sizing: border-box;
				float: left;
				width: 100%;
				li{
					display: block;
					position: relative;
					box-sizing: border-box;
					border:none;
					a{
						display: block;
						margin:0;
						padding:0.25rem 1em !important;
						background:none;
						border-bottom:1px solid rgba(255, 255, 255, 0.15);
						text-transform: uppercase;
						font-weight: 400;
						color:@text-color;
						white-space: normal;
						i{
							display:none;
						}
						&:hover{
							color: @theme-color-1;
							text-decoration:none;
						}
						&.active{
							color: @theme-color-1;
						}
						.icon-home{
							display: none;
						}
					}
					> .dropdown-menu{
						background: @white;
						padding:1rem;
						margin:0;
						display: none;
						position: absolute;
						width:100%;
						height:100vh;
						left:100%;
						top:0;
						.myanimated;
						z-index: 10;
						.box-shadow(none);
						border:none;
						box-sizing: border-box;
						overflow-x: hidden;
						.border-radius(0);
					}
					&.open{
						> .dropdown-menu{
							left:0;
							overflow-y: hidden;
						}
					}
					&.prevlevel{
						padding:0.5rem 0;
						border-bottom:2px solid rgba(255, 255, 255, 0.5);
						.menuGoBack{
							&:before{
								content:'\f104';
								font-family: @font-family-awesome;
								display: inline-block;
								font-size: @font-size-h2;
								color:@text-color;
								margin:0 0.5em 0 0;
								padding:0;
								vertical-align: sub;
							}
						}
					}
				}
			}
		}
	}
}
.footer{
	padding:2em 0;
	margin:0;
	background: @white;
	color:@gray-light;
	border:none;
	border-top:1px solid @gray-lightest;
	.container{
		background: none;
	}
	.footer-block {
		padding-top:0;
		margin-bottom:2em;
		text-align: left;
		font-size: @font-size-small;
		font-weight: 400;
		@media @tablet{
			text-align: left;
			padding-top:0;
			border-bottom:none;
			padding: 0 @grid-gutter-width/2;
		}
		@media @wide{
			padding: 0 @grid-gutter-width;
		}
		.block-title{
			margin:0 0 1.5em 0;
			text-align: left;
			font-size: @font-size-large;
			font-weight:400;
		    position: relative;
		    color: @theme-color-1;
		}
		a{
			color:@text-color;
			&:hover{
				color:@theme-color-1;
				text-decoration: underline;
			}
		}
		.fa{
			
			font-weight: normal;
			color:@gray-light;
			&:hover{
				color:@white;
				text-decoration: none;
			}
		}
		svg{
			*{
				fill:@white;
			}
		}
		ul, .nav{
			margin:0;
			padding:0;
			li{
				margin: 0 0 0.75rem 0;
				padding: 0;
				display: block;
				line-height: 1.2;
				*{
					display: inline;
				}
				a{
					margin: 0;
					padding: 0 0 0.5em 0;
					background:none;
					line-height: 1.2;
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
		&.contact{
			@media @xwide{
				border-left:1px solid @gray-lightest;
				margin:0;
			}
		}
	}
}

.content{
	padding:0;
	> .container{
		padding-top:0;
		padding-bottom:4em;
		@media @normal{
			padding-top:1em;
		}
		@media @wide{
			padding-top:2em;
		}
	}
	@media @normal{
		padding:0;
	}
	.content-col{
		padding:0;
		position: relative;
		@media @tablet{
			padding: 0 @grid-gutter-width/2;
		}
	}
}
 
.addthis_inline_share_toolbox{
	margin:0 0 1rem 0;
}

.not-front{
	.page-header{
		background: @theme-color-1;
		padding:0.5rem 1rem;
		font-size: @font-size-h3;
		color:@white;
		margin:0;
		text-transform: uppercase;
		font-weight: 400;
		@media @normal{
			padding:0.5rem 4rem 0.5rem 1rem;
			position: absolute;
			left:@grid-gutter-width/2;
			top:-2.25rem;
		}
		@media @wide{
			top:-2rem;
			font-size: @font-size-h2;
			padding:0.75rem 6rem 0.75rem 1.5rem;
		}
		@media @xwide{
			top:-2rem;
			font-size: @font-size-h1;
			padding:0.75rem 6rem 0.75rem 1.5rem;
		}
	}
}
.front{
	.page-header{
		display: none;
	}
	.content{
		> .container{
			padding-top:2rem;
			@media @normal{
				padding-top:3em;
			}
			@media @wide{
				padding-top:3em;
			}
		}
	}
	.addthis_inline_share_toolbox{
		display: none;
	}
}
.copy{
	padding:1rem 0;
	background: @theme-color-1;
	text-align: center;
	color:@white;
	font-size: @font-size-xsmall;
	a{
		color:@white;
		text-decoration: underline;
	}
	@media @normal{
		text-align:left;
	}
}

.sidebar{
	padding:0;
	@media @normal{
		padding:0 @grid-gutter-width/2;
	}
	.well{
		padding:0;
		border:none;
		margin:0;
		background: none;
		.box-shadow(none);
	}
	.sidebar-block{
		margin-bottom:2em;
		h2, h3{
			font-size: @font-size-base;
		}
		.block-title{
			margin:0 0 1.5em 0;
			text-align: left;
			text-transform: uppercase;
			font-size: @font-size-base;
			font-weight:700;
			padding-bottom:0.5rem;
			border-bottom:1px solid @gray-lighter;
			position: relative;
			&:after{
				position:absolute;
				width:60%;
				height:3px;
				background: @theme-color-1;
				content:'';
				display: block;
				left:0;
				bottom:-1px;
			}
		}
		.nav{
			list-style: none;
			margin:0;
			padding:0;
			.clearfix;
			ul{
				list-style: none;
				margin:0;
				padding:0;
			}
			li{
				margin:0;
				display: block;
				position: relative;
				.views-field{
					display: block;
					padding:0.5rem 0;
					border-bottom:1px solid @gray-lighter;
					font-weight: 400;
				}
				a{
					padding:0;
					background: none;
					display: inline-block;
					vertical-align: middle;
					color:@text-color;
					font-weight: 400;
					&:hover{
						background:none;
						text-decoration: underline;
						color:@theme-color-1;
					}
					&:active{
						color: @theme-color-2;
					}
				}
				&.open{
					a{
						color:@theme-color-1;
					}
				}
			}
		}
	}
}

.breadcrumb{
	margin:0 0 2rem 0;
	padding:2rem 0 0 0;
	background: none;
	font-weight: 400;
	li{
		color:@text-color;
		text-transform: uppercase;
		font-size: @font-size-small;
		a{
			color:@theme-color-1;
		}
	}
	@media @tablet{
		margin:0 0 3rem 0;
	}
	@media @wide{
		margin:0 0 3rem 0;
	}
}
.topimg{
	position: relative;
	min-height:10px;
	.field-name-field-top-image{
		img{
			width:100%;
			height:120px;
			.object-fit(cover);
			@media @tablet{
				height:180px;
			}
			@media @normal{
				height:200px;
			}
			@media @wide{
				height:220px;
			}
			@media @xwide{
				height:240px;
			}
		}
	}
	@media @tablet{
		min-height:10px;
	}
	@media @normal{
		min-height:25px;
	}
	@media @wide{
		min-height:30px;
	}
	@media @xwide{
		min-height:40px;
	}
}
.front{
	.topimg{
		position: relative;
		
		.clearfix;
		.topimg-img{
			img{
				width:100% !important;
				height:320px !important;
				
				.object-fit(cover);
				@media @tablet{
					height:320px !important;
					
				}
				@media @normal{
					height:480px !important;
					
				}
				@media @wide{
					height:480px !important;
					
				}
				@media @xwide{
					height:620px !important;
				
				}
			}
		}
	}
	.hp-slider{
		.slick-track{
			.slick-slide{
				.node-hp-slider{
					position: relative;
					.field-name-field-image{
						img{
							width:100% !important;
							height:320px !important;
							
							.object-fit(cover);
							@media @tablet{
								height:320px !important;
							}
							@media @normal{
								height:480px !important;
							}
							@media @wide{
								height:480px !important;
							}
							@media @xwide{
								height:620px !important;
							}
						}
					}
					.field-name-body{
						position: absolute;
						z-index: 10;
						width:100%;
						left:0;
						top:5rem;
						text-align: center;
						font-weight: 700;
						font-size: @font-size-h1;
						color:@white;
						.text-shadow(0 0 10px rgba(0, 0, 0, 0.75));
						strong{
							text-decoration: underline;
						}
						@media @tablet{
							top:7rem;
							font-size: @font-size-h1*1.5;
						}
						@media @normal{
							top:8rem;
							font-size: @font-size-h1*1.5;
						}
						@media @wide{
							top:12rem;
							font-size: @font-size-h1*1.75;
						}
						@media @xwide{
							top:16rem;
							font-size: @font-size-h1*2;
						}
					}
				}
			}
		}
	}
	.hp-img-txt{
		position: absolute;
		z-index: 10;
		width:100%;
		left:0;
		top:5rem;
		text-align: center;
		font-weight: 700;
		font-size: @font-size-h1;
		color:@white;
		.text-shadow(0 0 10px rgba(0, 0, 0, 0.75));
		strong{
			text-decoration: underline;
		}
		@media @tablet{
			top:7rem;
			font-size: @font-size-h1*1.5;
		}
		@media @normal{
			top:8rem;
			font-size: @font-size-h1*1.5;
		}
		@media @wide{
			top:12rem;
			font-size: @font-size-h1*1.75;
		}
		@media @xwide{
			top:16rem;
			font-size: @font-size-h1*2;
		}
	}
	.sliding-links{
		color:@white;
		.block-title{
			text-align: left;
			font-size: @font-size-h2;
			color:@white;
			margin:0;
			padding:1rem 2rem;
			font-weight: 300;
			cursor: pointer;
			position: relative;
			&:after{
				content:'';
				color:@theme-color-3;
				font-size: @font-size-large;
				display: block;
				margin: 0.5rem 0 0 0;
			}
			&:before{
				position: absolute;
				right:2rem;
				top:1rem;
				content:'';
				width:48px;
				height:48px;
				border:1px solid @theme-color-3;
				.border-radius(24px);
				text-align: center;
				padding:0.5rem;
				box-sizing: border-box;
				display: block;
			}
		}
		>ul{
			margin: 0;
			padding:1rem 2rem;
			list-style: none;
			display: none;
			li{
				margin:0 0 0.5rem 0;
				padding:0 0 0.5rem 0;
				font-weight: 300;
				a{
					color: @white;
					text-decoration: none;
					&:hover{
						text-decoration:underline;
					}
				}
			}
		}
		&.project-links{
			background: @theme-color-2;
			>ul{
				background: @theme-color-2-hover;
				li{
					border-bottom:1px solid @theme-color-2;
				}
			}
			.block-title{
				&:after{
					content:'View our latest projects';
				}
				&:before{
					content:url(../images/projects.svg);
				}
			}
		}
		&.product-links{
			background: @theme-color-1;
			>ul{
				background: @theme-color-1-hover;
				li{
					border-bottom:1px solid @theme-color-1;
				}
			}
			.block-title{
				&:after{
					content:'View our product range';
				}
				&:before{
					content:url(../images/products.svg);
				}
			}
		}
		@media @tablet{
			width:50%;
			float:left;
			z-index:10;
			position:absolute;
			bottom:0;
			&.product-links{
				left:0;
			}
			&.project-links{
				left:50%;
			}
		}
		@media @normal{
			width:32%;
			float:left;
			z-index:10;
			position:absolute;
			bottom:0;
			&.product-links{
				left:16%;
			}
			&.project-links{
				left:52%;
			}
		}
		@media @xwide{
			width:24%;
			float:left;
			z-index:10;
			position:absolute;
			bottom:0;
			&.product-links{
				left:24%;
			}
			&.project-links{
				left:52%;
			}
		}
	}
}
.hp_toptext{
	padding: .5rem 0 1rem 0;
	font-size: @font-size-large;
	text-align: center;
	@media @xwide{
		padding: 1rem 0 1rem 0;
		font-size: @font-size-h5;
		margin:0 auto;
		width:80%;
	}
}


.grid-list{
	.view-content{
		.flex-container(row, wrap, center, stretch, flex-start);
		.views-row{
			.flex-element(0, 0, 0, 100%);
			margin-bottom:2rem;
			text-align: left;
			.views-field-field-image,
			.field-name-field-image{
				img{
					width:100%;
					max-width: 480px;
					height:240px;
					.object-fit(cover);
					display: inline-block;
					margin:0;
				}
			}
			.views-field-title,
			.views-field-name,
			.field-name-title{
				margin:1rem 0;
				a{
					color:@theme-color-1;
					font-size:@font-size-h3;
					text-transform: normal;
					font-weight: 500;
				}
			}
			.views-field-view-node,
			.field-name-more-link,
			.field-name-node-link{
				a{
					.btn;
					.bigbtn;
					.whitebtn;
				}
			}
			@media @tablet{
				.flex-element(0, 0, 0, 50%);
				padding:0 1rem;
			}
			@media @normal{
				.flex-element(0, 0, 0, 33.333333%);
				margin-bottom:3rem;
			}
			@media @xwide{
				.flex-element(0, 0, 0, 33.333333%);
				margin-bottom:4rem;
			}
		}
	}
}
.view-projects{
	.grid-list;
	&.hp-fearured-project{
		margin:2rem 0;
		.view-header{
			.clearfix;
			border-bottom:1px solid @gray-lightest;
			padding-bottom:0.5rem;
			margin-bottom:2rem;
			.featured-header{
				float:left;
				text-transform: uppercase;
				font-size: @font-size-h3;
			}
			a{
				float:right;
				text-transform: uppercase;
			}
		}
	}
}
.view-product-categories-1-st-level,
.view-products-listing{
	.grid-list;
}
#block-views-team-block{
	margin-top:3rem;
}
.view-team{
	.grid-list;
	.views-field-field-job-title{
		font-size: @font-size-small;
		line-height: 1;
		margin:-1rem 0 1rem 0;
	}
}
.node-type-project{
	.node-project{
		.group-img{
			padding:0;
			margin: 0 0 2rem 0;
			.field-name-field-video{
				margin-top:1rem;
				iframe{
					width:100%;
					height:340px;
				}
			}
			.field-name-field-image{
				.field-items{
					.clearfix;
					.field-item{
						width:32.333333%;
						margin: 0 0.5%;
						float:left;
						img{
							width:100%;
							height:120px;
							.object-fit(cover);
						}
						&:first-child{
							width:100%;
							margin:0 0 0.5rem 0;
							img{
								height:220px;
							}
						}
						@media @wide{
							width:31.333333%;
							margin: 0 1%;
							img{
								height:150px;
							}
							&:first-child{
								width:100%;
								margin:0 0 0.5rem 0;
								img{
									height:360px;
								}
							}
						}
					}
				}
			}
			@media @tablet{
				padding: 0 2rem 0 0;
				margin:0;
			}
		}
		.group-descr{
			padding:0;
			margin: 0;
			@media @tablet{
				padding: 0 0 0 2rem;
				margin:0;
			}
			.block-webform{
				border:1px solid @theme-color-1;
				padding:1rem 1.5rem;
				.border-radius(5px);
				margin-top:2rem;
				.block-title{
					font-size: @font-size-base;
					text-transform: uppercase;
					color:@theme-color-1;
					text-align: left;
					margin:0;
					padding:0;
					position: relative;
					cursor: pointer;
					font-weight: 700;
					&:after{
						font-weight:normal;
						content:'\f078';
						font-family: @font-family-awesome;
						position: absolute;
						right:0;
						top:0;
						font-size: @font-size-small;
						line-height: 1;
					}
				}
				.webform-client-form{
					margin:1.5rem 0 0.5rem 0;
					display:none;
					.form-control{
						padding: 0.5rem;
						border-color: @gray-lighter;
						.border-radius(0);
						font-weight: 300;
						height:auto;
					}
					.form-actions{
						padding-left:0;
						text-align: left;
						.btn{
							.bluebtn;
							text-transform: uppercase;
							font-size: @font-size-small;
							padding:0.75rem 2rem;
						}
					}
				}
				&.open{
					.webform-client-form{
						display: block;
					}
					.block-title{
						&:after{
							content:'\f077';
						}
					}
				}
			}
		}
	}
}
.node-type-product{
	.node-product{
		.group-img{
			padding:0;
			margin: 0 0 2rem 0;
			.field-name-field-video{
				margin-top:1rem;
				iframe{
					width:100%;
					height:340px;
				}
			}
			.field-name-field-image{
				.field-items{
					.clearfix;
					.field-item{
						width:32.333333%;
						margin: 0 0.5%;
						float:left;
						img{
							width:100%;
							height:120px;
							.object-fit(cover);
						}
						&:first-child{
							width:100%;
							margin:0 0 0.5rem 0;
							img{
								height:220px;
							}
						}
						@media @wide{
							width:31.333333%;
							margin: 0 1%;
							img{
								height:150px;
							}
							&:first-child{
								width:100%;
								margin:0 0 0.5rem 0;
								img{
									height:360px;
								}
							}
						}
					}
				}
			}
			@media @tablet{
				padding: 0 2rem 0 0;
				margin:0;
			}
		}
		.group-descr{
			padding:0;
			margin: 0;
			@media @tablet{
				padding: 0 0 0 2rem;
				margin:0;
			}
			.block-webform{
				border:1px solid @theme-color-1;
				padding:1rem 1.5rem;
				.border-radius(5px);
				margin-top:2rem;
				.block-title{
					font-size: @font-size-base;
					text-transform: uppercase;
					color:@theme-color-1;
					text-align: left;
					margin:0;
					padding:0;
					position: relative;
					cursor: pointer;
					font-weight: 700;
					&:after{
						font-weight:normal;
						content:'\f078';
						font-family: @font-family-awesome;
						position: absolute;
						right:0;
						top:0;
						font-size: @font-size-small;
						line-height: 1;
					}
				}
				.webform-client-form{
					margin:1.5rem 0 0.5rem 0;
					display:none;
					.form-control{
						padding: 0.5rem;
						border-color: @gray-lighter;
						.border-radius(0);
						font-weight: 300;
						height:auto;
					}
					.form-actions{
						text-align: left;
						padding-left:0;
						.btn{
							.bluebtn;
							text-transform: uppercase;
							font-size: @font-size-small;
							padding:0.75rem 2rem;
						}
					}
				}
				&.open{
					.webform-client-form{
						display: block;
					}
					.block-title{
						&:after{
							content:'\f077';
						}
					}
				}
			}
		}
		.horizontal-tabs{
			margin:0;
			padding:0;
			border:none;
			.horizontal-tabs-pane{
				padding:0;
				.border-radius(0);
				.box-shadow(none);
				margin:0;
				.panel-body{
					padding:2rem 0 0 0;
				}
			}
			.horizontal-tabs-list{
				background: none;
				background-color: none;
				border:none;
				border-bottom:1px solid @gray-lighter;
				li{
					border:none;
					background: none;
					padding:0;
					margin: 0 0 -1px 0;
					border-bottom:1px solid @gray-lighter;
					border-top:2px solid @white;
					a{
						padding:0.5rem 1rem;
						text-transform: uppercase;
						font-weight: 700;
						color:@theme-color-1;
						border-bottom:1px solid @white;
					}
					&:hover{
						background: none;
						border-bottom:1px solid @theme-color-1;
						a{
							color:@theme-color-1-hover;
							background: none;
							border-bottom:1px solid @theme-color-1;
						}
					}
					&.selected{
						border-bottom:1px solid @white;
						border-top:2px solid @theme-color-1;
						a{
							color:@text-color;
							strong{
								color:@text-color;
							}
						}
						&:hover{
							border-bottom:1px solid @white;
							a{
								border-bottom:1px solid @white;
							}
						}
					}
				}
			}
		}
	}
	.group-related-projects{
		>h3{
			color: @text-color;
			margin:4rem 0 2rem 0;
			padding: 0 0 1rem 0;
			text-transform: uppercase;
			border-bottom: 1px solid @gray-lightest;
			span{
				color:@text-color;
			}
		}
	}
	.field-name-field-related-projects > .field-items{
		.flex-container(row, wrap, center, stretch, flex-start);
		>.field-item{
			.flex-element(0, 0, 0, 100%);
			margin-bottom:2rem;
			text-align: center;
			.field-name-field-image{
				img{
					width:100%;
					max-width: 480px;
					height:240px;
					.object-fit(cover);
					display: inline-block;
					margin:0;
				}
			}
			.views-field-title,
			.views-field-name,
			.field-name-title{
				margin:1rem 0;
				a{
					color:@text-color;
					text-transform: uppercase;
					font-weight: 700;
				}
			}
			.views-field-view-node,
			.field-name-more-link,
			.field-name-node-link{
				a{
					.btn;
					.bigbtn;
					.whitebtn;
				}
			}
			@media @tablet{
				.flex-element(0, 0, 0, 50%);
				padding:0 1rem;
			}
			@media @normal{
				.flex-element(0, 0, 0, 33.333333%);
				margin-bottom:3rem;
			}
			@media @xwide{
				.flex-element(0, 0, 0, 33.333333%);
				margin-bottom:4rem;
			}
		}
	}
	.field-collection-container{
		margin-top:2rem;
		> .field-name-field-serie{
			> .field-items{
				.flex-container(row, wrap, flex-start, stretch, flex-start);
				> .field-item{
					.flex-element(0, 0, 0, 100%);
					margin-bottom:2rem;
					border:none;
					.field-collection-view{
						margin:0;
						padding:0;
						border:none;
					}
					.field-collection-view-links{
						display: none;
					}
					@media @tablet{
						.flex-element(0, 0, 0, 33.333333%);
						padding:0 2rem 0 0;
					}
					@media @wide{
						.flex-element(0, 0, 0, 33.333333%);
					}
				}
			}
			.field-name-field-serie-name{
				font-weight: 700;
				text-transform: uppercase;
				margin-bottom:1rem;
			}
			.field-type-file{
				.field-items{
					padding-left: 1rem;
					.field-item{
						margin-bottom:0.5rem;
						.file{
							img{
								margin-right:0.25rem;
							}
						}
					}
				}
			}
		}
	}
}
.page-taxonomy-term{
	.region-content{
		.group-img{
			padding:0;
			margin: 0 0 2rem 0;
			.field-name-field-video{
				margin-top:1rem;
				iframe{
					width:100%;
					height:340px;
				}
			}
			.field-name-field-image{
				.field-items{
					.clearfix;
					.field-item{
						width:32.333333%;
						margin: 0 0.5%;
						float:left;
						img{
							width:100%;
							height:120px;
							.object-fit(cover);
						}
						&:first-child{
							width:100%;
							margin:0 0 0.5rem 0;
							img{
								height:220px;
							}
						}
						@media @wide{
							width:31.333333%;
							margin: 0 1%;
							img{
								height:150px;
							}
							&:first-child{
								width:100%;
								margin:0 0 0.5rem 0;
								img{
									height:360px;
								}
							}
						}
					}
				}
			}
			@media @tablet{
				padding: 0 2rem 0 0;
				margin:0;
			}
		}
		.group-descr{
			padding:0;
			margin: 0;
			@media @tablet{
				padding: 0 0 0 2rem;
				margin:0;
			}
			.block-webform{
				border:1px solid @theme-color-1;
				padding:1rem 1.5rem;
				.border-radius(5px);
				margin-top:2rem;
				.block-title{
					font-size: @font-size-base;
					text-transform: uppercase;
					color:@theme-color-1;
					text-align: left;
					margin:0;
					padding:0;
					position: relative;
					cursor: pointer;
					font-weight: 700;
					&:after{
						font-weight:normal;
						content:'\f078';
						font-family: @font-family-awesome;
						position: absolute;
						right:0;
						top:0;
						font-size: @font-size-small;
						line-height: 1;
					}
				}
				.webform-client-form{
					margin:1.5rem 0 0.5rem 0;
					display:none;
					.form-control{
						padding: 0.5rem;
						border-color: @gray-lighter;
						.border-radius(0);
						font-weight: 300;
						height:auto;
					}
					.form-actions{
						text-align: left;
						padding-left:0;
						.btn{
							.bluebtn;
							text-transform: uppercase;
							font-size: @font-size-small;
							padding:0.75rem 2rem;
						}
					}
				}
				&.open{
					.webform-client-form{
						display: block;
					}
					.block-title{
						&:after{
							content:'\f077';
						}
					}
				}
			}
		}
	}
}

.field-name-share{
	text-align: right;
}
.block-quicktabs{
	.quicktabs-tabs{
		margin:0;
		padding:0;
		background: none;
		background-color: none;
		border:none;
		border-bottom:1px solid @gray-lighter;
		display: block;
		.clearfix;
		li{
			border:none;
			background: none;
			padding:0;
			margin: 0 0 -1px 0;
			border-bottom:1px solid @gray-lighter;
			border-top:2px solid @white;
			display: block;
			float: left;
			a{
				padding:0.5rem 1rem;
				text-transform: uppercase;
				font-weight: 700;
				color:@theme-color-1;
				border-bottom:1px solid @white;
				display: block;
				position: relative;
				text-decoration: none;
			}
			&:hover{
				background: none;
				border-bottom:1px solid @theme-color-1;
				a{
					color:@theme-color-1-hover;
					background: none;
					border-bottom:1px solid @theme-color-1;
				}
			}
			&.active{
				border-bottom:1px solid @white;
				border-top:2px solid @theme-color-1;
				a{
					color:@text-color;
					strong{
						color:@text-color;
					}
				}
				&:hover{
					border-bottom:1px solid @white;
					a{
						border-bottom:1px solid @white;
					}
				}
			}
		}
	}
	.quicktabs_main{
		padding:2rem 0 0 0;
	}
}
.views-exposed-form{
	.views-exposed-widget{
		float: none;
		width: 100%;
		padding:0;
		margin:0;
		.form-item{
			.flex-container(row, wrap, center, flex-start, flex-start);
			>.form-item{
				.flex-element(0, 1, 1, auto);
				padding:0;
				margin: 0 0 2rem 0;
				a{
				
					font-size: 11pt;
					padding:0.25rem .8rem;
					border-bottom:1px solid @white;
					&.active{
						font-weight: 700;
						border-top:4px solid @theme-color-1;
						background: none;
						color:@text-color;
					}
					&:hover{
						background: none;
						text-decoration: none;
						border-top:4px solid @theme-color-1;
					}
				}
			}
		}
	}
}
.node-type-team{
	.node-team{
		.group-img{
			padding:0;
			margin: 0 0 2rem 0;
			.field-name-field-image{
				.field-items{
					.clearfix;
					.field-item{
						width:32.333333%;
						margin: 0 0.5%;
						float:left;
						img{
							width:100%;
							height:120px;
							.object-fit(cover);
						}
						&:first-child{
							width:100%;
							margin:0 0 0.5rem 0;
							img{
								height:220px;
							}
						}
						@media @wide{
							width:31.333333%;
							margin: 0 1%;
							img{
								height:150px;
							}
							&:first-child{
								width:100%;
								margin:0 0 0.5rem 0;
								img{
									height:360px;
								}
							}
						}
					}
				}
			}
			@media @tablet{
				padding: 0 2rem 0 0;
				margin:0;
			}
		}
		.group-descr{
			padding:0;
			margin: 0;
			.field-name-title{
				h3{
					margin: 0 0 1rem 0;
				}
			}
			.field-name-field-job-title{
				margin-bottom:1rem;
				color: @theme-color-1;
			}
			@media @tablet{
				padding: 0 0 0 2rem;
				margin:0;
			}
		}
	}
}
.contacts-map{
	.col-xs-12{
		@media @tablet{
			padding-right:1rem;
			padding-left:0;
		}
	}
	h4{
		color:@theme-color-1;
		text-transform: uppercase;
		margin-top:0;
		font-size: @font-size-base;
		font-weight: 700;
	}
	h5{
		text-transform: uppercase;
		font-weight: 700;
		font-size: @font-size-base;
	}
}
.contacts-form{
	margin-bottom:4rem;
	.form-item{
		margin-bottom:1rem;
		margin-top:0.5rem;
		&.col-xs-12{
			@media @tablet{
				padding-left:0;
				padding-right:2rem;
			}
		}
		.control-label{
			font-weight: 700;
			line-height: 1.2;
		}
		input, textarea, select{
			.border-radius(0);
			padding:0.75rem 1rem;
			height:42px;
		}
		textarea{
			height: 160px;
		}
		.form-managed-file{
			>*{
				display: inline-block;
				vertical-align: top;
				height:36px;
				padding:0.35rem 0.5rem;
			}
			.btn-primary{
				width:100px;
				padding:0;
				*{
					vertical-align: middle;
				}
				.icon{
					padding:0.5rem 0;
				}
			}
			.form-file{
				width:calc(~'100% - 100px');
			}
		}
	}
	.webform-component--privacy-txt{
		padding-left:1rem;
		@media @tablet{
			padding-left:0;
			float:left;
			width:50%;
		}
	}
	.webform-component--subscribe{
		.control-label{
			*{
				display: inline-block;
				vertical-align: middle;
			}
			input{
				margin-top:0;
			}
		}
	}
	.form-actions{
		.webform-submit{
			.bigbtn;
			.bluebtn;
			padding: 1.25rem 2rem
		}
		padding-left:1rem;
		@media @tablet{
			padding-left:0;
			float:left;
			width:50%;
			text-align:left;
			clear:none;
		}
	}
}
.field-name-enquiry-about-this-project{
	margin-bottom:4rem;
	.form-item{
		margin-bottom:1rem;
		margin-top:0.5rem;
		&.col-xs-12{
			@media @tablet{
				padding-left:0;
				padding-right:2rem;
			}
		}
		.control-label{
			font-weight: 700;
			line-height: 1.2;
		}
		input, textarea, select{
			.border-radius(0);
			padding:0.75rem 1rem;
			height:42px;
		}
		textarea{
			height: 160px;
		}
		.form-managed-file{
			>*{
				display: inline-block;
				vertical-align: top;
				height:36px;
				padding:0.35rem 0.5rem;
			}
			.btn-primary{
				width:100px;
				padding:0;
				*{
					vertical-align: middle;
				}
				.icon{
					padding:0.5rem 0;
				}
			}
			.form-file{
				width:calc(~'100% - 100px');
			}
		}
	}
	.form-actions{
		.webform-submit{
			.bigbtn;
			.bluebtn;
			padding: 1.25rem 2rem
		}
		padding-left:1rem;
		@media @tablet{
			padding-left:0;
			float:left;
			width:50%;
			text-align:left;
			clear:none;
		}
	}
}
.view-blog{
	.views-row{
		.clearfix;
		margin-bottom:2rem;
		padding-bottom:2rem;
		border-bottom:1px solid @gray-lightest;
		h4{
			margin: 0 0 1rem 0;
		}
		.field-name-post-date{
			font-size: @font-size-small;
			font-style: italic;
			margin-bottom:1rem;
		}
	}
}
.node-article.view-mode-full{
	h4{
			margin: 0 0 1rem 0;
		}
		.field-name-post-date{
			font-size: @font-size-small;
			font-style: italic;
			margin-bottom:1rem;
		}
}
.slick-projects{
	.slick-track,
	.slick__slider{
		.slide{
			text-align: left;
			margin-bottom:2rem;
			.views-field-field-image{
				img{
					width:100%;
					max-width: 480px;
					height:240px;
					.object-fit(cover);
					display: inline-block;
					margin:0;
				}
			}
			.views-field-title{
				margin:1rem 0;
				a{
					color:@theme-color-1;
					font-size:@font-size-h3;
					text-transform: normal;
					font-weight: 500;
				}
			}
			.views-field-view-node{
				a{
					.btn;
					.bigbtn;
					.whitebtn;
				}
			}

			@media @tablet{
				padding:0 @grid-gutter-width/2;
				.views-field-view-node{
					right:2rem;
				}
			}
		}
	}
}

.slick__arrow{
	position: absolute;
	top:50%;
	left:0;
	width:100%;
	z-index:10;
	.fa{
		position: absolute;
		font-size: 0;
		&:before{
			font-size:@font-size-h2;
			color:@theme-color-2 !important;
		}
	}
	.fa-chevron-left{
		left:-2rem;
	}
	.fa-chevron-right{
		right:-2rem;
	}
}

.slick-arrow{
	&.slick-prev,
	&.slick-next{
		&:before{
			font-family:@font-family-awesome;
			font-size: @font-size-h1;
			color:@white;
			font-weight: normal;
		}
	}
	&.slick-prev{
		&:before{
			content:'\f053';
		}
	}
	&.slick-next{
		&:before{
			content:'\f054';
		}
	}
}

#cboxPrevious,
#cboxNext{
	top:50%;
	position: absolute;
	font-size: 0;
	&:before{
		content:'';
		font-family:@font-family-awesome;
		font-size: @font-size-h1;
		color:@white;
		font-weight: normal;
		background: rgba(0, 0, 0, 0.33);
		width:50px;
		height:50px;
		.border-radius(25px);
		display: inline-block;
		text-align: center;
		padding-top: 0.35rem;
	}
}

#cboxPrevious{
	left:1rem;
	&:before{
		content:'\f053';
	}
}
#cboxNext{
	right:1rem;
	&:before{
		content:'\f054';
	}
}
.g-recaptcha{
	padding-bottom:2rem;
	width: 100%;
	float:left;
}


.view-homepage-slider{
	.field-name-body{
		p{
			text-align: center;
			font-size:17pt;
			line-height: 25px;
			color:#fff;
			margin-top:5rem;
			margin-bottom:2rem;
			padding:0 40px;
			@media @tablet{
				font-size:30pt;	
				margin-top:5rem;
				margin-bottom:3rem;
					}
			@media @normal{
				font-size:40pt;
				margin-top:5rem;
				margin-bottom:4rem;
					}
			@media @xwide{
				font-size:40pt;
				margin-top:5rem;
				margin-bottom:4rem;
					}
		}
	}
}


.home-services-block{
	.view-content{
		.flex-container(row, wrap, center, stretch, flex-start);
		.views-row{
			.flex-element(0, 0, 0, 100%);
			margin-bottom:2rem;
			text-align: left;
			.views-field-field-image,
			.field-name-field-image{
				img{
					width:100%;
					max-width: 480px;
					height:240px;
					.object-fit(cover);
					display: inline-block;
					margin:0;
				}
			}
			.views-field-title,
			.views-field-name,
			.field-name-title{
				margin:1rem 0;
				a{
					color:@theme-color-1;
					font-size:@font-size-h3;
					text-transform: normal;
					font-weight: 500;
				}
			}
			.views-field-view-node,
			.field-name-more-link,
			.field-name-node-link{
				a{
					.btn;
					.bigbtn;
					.whitebtn;
				}
			}
			@media @tablet{
				.flex-element(0, 0, 0, 50%);
				padding:0 1rem;
			}
			@media @normal{
				.flex-element(0, 0, 0, 33.333333%);
				margin-bottom:3rem;
			}
			@media @xwide{
				.flex-element(0, 0, 0, 25%);
				margin-bottom:4rem;
			}
		}
	}
}


#block-views-services-block{
	margin-top:1rem;
			.view-header{
				.clearfix;
				border-bottom:1px solid @gray-lightest;
				padding-bottom:0.5rem;
				margin-bottom:2rem;
				.featured-header{
					float:left;
					text-transform: uppercase;
					font-size: @font-size-h3;
				}
				a{
					float:right;
					text-transform: uppercase;
				}
			}
		}
