/**
 * Variable Overrides.
 *
 * Modify this file to override the Bootstrap Framework variables. You can copy
 * these variables directly from ../bootstrap/less/variables.less to this file.
 */

// Set the proper directory for the Bootstrap Glyphicon font.
@icon-font-path: '../bootstrap/fonts/';


@gray-base:              #000;
@gray-dark:              #222;
@gray-dark-lighter:      #333;
@gray-mid-dark:          #4f4f4f;
@gray:                   #666;
@gray-light:             #828282;
@gray-more-light:        #bdbdbd;
@gray-lighter:           #ccc;
@gray-lightest:          #ebebeb;
@gray-lightest-white:    #f4f4f4;
@gray-trans: rgba(0, 0, 0, 0.25);
@white: #fff;

@body-bg: @white;

/*See all colors in _colors.less*/
@theme-color-1: #1991C1; /* Blue -light*/
@theme-color-1-hover: darken(@theme-color-1, 5%);
@theme-color-2: #136B8F; /*Blue-darken*/
@theme-color-2-hover: darken(@theme-color-2, 5%);
@theme-color-3: #7fc2da; /* light blue*/

@text-color:            @gray-mid-dark;
@link-color:            @theme-color-1;
@link-hover-color:      @theme-color-1-hover;

@font-family-sans-serif: 'Roboto', 'Helvetica', sans-serif;
@font-family-awesome:  'FontAwesome';

@font-family-base:        @font-family-sans-serif;

@font-size-base:          11pt;
@font-size-large:         12pt;
@font-size-small:         10pt;
@font-size-xsmall:        9pt;
@font-size-h1:            20pt;
@font-size-h2:            18pt;
@font-size-h3:            15pt;
@font-size-h4:            13.5pt; 
@font-size-h5:            13.5pt;
@line-height-base:        1.6;


@pagination-color:                     @theme-color-1;
@pagination-bg:                        @white;
@pagination-border:                    @gray-lighter;

@pagination-hover-color:               @theme-color-1;
@pagination-hover-bg:                  @gray-lighter;
@pagination-hover-border:              @gray-lighter;

@pagination-active-color:              @white;
@pagination-active-bg:                 @theme-color-1;
@pagination-active-border:             @theme-color-1;

@pagination-disabled-color:            @gray-light;
@pagination-disabled-bg:               @white;
@pagination-disabled-border:           @gray-lighter;

@grid-gutter-width:         2rem;
@screen-xlg:                  1300px;
@screen-xlg-min:              @screen-xlg;
@screen-xlg-desktop:          @screen-xlg-min;
@screen-xlg-max:               (@screen-xlg-min - 1);
@container-xlarge-desktop:      (1240px + @grid-gutter-width*2);
@container-xlg:                 @container-xlarge-desktop;